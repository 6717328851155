import React from 'react';
import MainPage from './pages/MainPage';
import './App.css';

function App() {
  return (
    <div>
      <MainPage />
    </div>
    
  );
}

export default App;
